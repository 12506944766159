import Vue from '../main'

export const goSlotPage = (hyperLink) => {
  Vue.$router.push({
    name: 'slot',
    params: {
      slotName: hyperLink,
    },
  })
}
